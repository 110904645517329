<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>FAQ</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          Create FAQ
        </b-button>
      </div>
      <b-table
        responsive="sm"
        :items="contents.content"
        :fields="fields"
        @row-clicked="showDetails"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="contentForm">
          <b-form @submit.prevent="is_update ? updatePageContent() : createPageContent()">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Question"
                  label-for="v-question"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="faq.question"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Question"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Answer"
                  label-for="v-answer"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="anwser"
                    rules="required"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="faq.answer"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Answer"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BRow, BCol, BPagination, BFormTextarea, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      items: [],
      required,
      faq: { question: '', answer: '' },
      fields: [{ key: 'question', label: 'questions' }, { key: 'answer', label: 'Answer', sortable: true }],
    }
  },
  computed: {
    contents() {
      return this.$store.state.page_content.faq_contents
    },
  },
  mounted() {
    this.getFaqContent()
  },
  methods: {
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.emptyData()
    },
    emptyData() {
      this.faq = { question: '', answer: '' }
    },
    getFaqContent() {
      return this.$store.dispatch('page_content/getAllFAQContent', 'faqs')
    },
    createPageContent() {
      this.$refs.contentForm.validate().then(success => {
        if (success) {
          if (this.contents.id !== 0) {
            const newContent = [this.faq, ...this.contents.content]
            this.contents.content = newContent
            const data = { id: this.contents.id, name: 'faqs', content: this.contents.content }
            this.$store.dispatch('page_content/updateContent', data)
            this.hide()
          } else {
            const data = { name: 'faqs', content: [this.faq] }
            this.$store.dispatch('page_content/createFAQContent', data)
            this.hide()
          }
        }
      })
    },
    updatePageContent() {
      const data = { id: this.contents.id, name: 'faqs', content: this.contents.content }
      this.$store.dispatch('page_content/updateFAQContent', data)
      this.hide()
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const newContent = this.contents.content.filter(item => item.question !== this.faq.question)
          const data = { id: this.contents.id, name: 'faqs', content: newContent }
          this.$store.dispatch('page_content/updateFAQContent', data)
          this.hide()
        }
      })
    },
    showDetails(item) {
      this.is_update = true
      this.faq = item
      this.showModal()
    },
  },
}
</script>

<style>

</style>
